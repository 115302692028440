
table {
  padding:20px;
}

th {
  padding-bottom:10px;
}

td {
  padding:10px;
}

tr, th {
  text-align: right !important;
}


td:first-child {
  width: 100px;
}

tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}